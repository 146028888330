<template>
    <v-row no-gutters justify="center" align-content="center" class="pa-5" style="height: 100%;">
        <v-col cols="8" sm="6" md="4" lg="4" xl="4" class="pa-0">
            <v-banner lines="one" color="grey lighten-5">
                <v-banner-icon>
                    <v-progress-circular size="16" width="2" color="#3F51B5" indeterminate></v-progress-circular>
                </v-banner-icon>
                <v-banner-text class="px-2">
                    Signing out...
                </v-banner-text>
            </v-banner>
        </v-col>
    </v-row>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
// import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';
// import { client } from '@/client';
// import { toText } from '@/sdk/time';

export default {
    data: () => ({
        sessionExpiresText: null,
        refreshAfter: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            focus: (state) => state.focus,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        authenticatedExpiresText() {
            if (typeof this.session.authenticatedNotAfter === 'number' && this.session.authenticatedNotAfter > 0) {
                // return toText(this.session.authenticatedNotAfter - Date.now());
                return new Date(this.session.authenticatedNotAfter).toString();
            }
            return undefined;
        },
        nextRefreshText() {
            if (typeof this.session.nextRefresh === 'number' && this.session.nextRefresh > 0) {
                // return toText(this.session.nextRefresh - Date.now());
                return new Date(this.session.nextRefresh).toString();
            }
            return undefined;
        },
    },
    watch: {
        focus() {
            // this.refreshUserSession(); // App.vue already calls refresh which is loadSession and loadUser every time window gains focus
        },
    },
    methods: {
        async logout() {
            try {
                this.$store.commit('loading', { logout: true });
                await this.$client.main().authn.logout();
                this.$router.replace({ name: 'front' });
            } catch (err) {
                console.error('failed to load session', err);
            } finally {
                this.$store.commit('loading', { logout: false });
            }
        },
    },
    mounted() {
        this.logout();
    },
};
</script>
